/* eslint-disable global-require object-curly-newline */
// The various object modules
// Projects, Properties, Issues, Regions, Contacts,
// The various collection modules
// projects, properties, issues,
import Vue from 'vue';
import VueRouter from 'vue-router';

import globalRouteGuard from './routeGuard';
import ModuleRoute from '@acorns-ui/Modules/CollectionModule/ModuleRoute';
import projectRoutes from '@acorns-ui/Modules/ProjectModule/ProjectRoutes';
import issueRoutes from '@acorns-ui/Modules/IssueModule/IssueRoutes';
//import contactRoutes from '@acorns-ui/Modules/ContactModule/ContactRoutes';
import templateRoutes from '@acorns-ui/Modules/ProjectModule/TemplateRoutes';
import templateAdminRoutes from '@acorns-ui/Modules/ProjectModule/TemplateAdminRoutes';
import roleAdminRoutes from '@acorns-ui/Modules/RoleModule/RoleAdminRoutes';
import propertyTypeRoutes from '@acorns-ui/Modules/PropertyModule/PropertyTypeRoutes';
import issueTypeRoutes from '@acorns-ui/Modules/IssueModule/IssueTypeRoutes';
import roleRoutes from '@acorns-ui/Modules/RoleModule/RoleRoutes';
import AdminRoutes from '@acorns-ui/Modules/AdminModule/AdminRoutes';
import LibraryRoutes from '@acorns-ui/Modules/LibraryModule/LibraryRoutes';
import RegionRoutes from '@acorns-ui/Modules/RegionModule/RegionRoutes';



//import ToolsRoutes from '@acorns-ui/Modules/ToolsModule/ToolsRoutes';

// updated to new moduleRoute method
//import contactRoute from './contact';
//import projectRoute from './project';
//import regionRoute from './region';
//import roleRoute from './roles'; // not using new method but reviewed
//import issueRoute from './issue';

// leaving the template route alone for now
//import templateRoute from './template';
import spatialRoute from './spatial';
//import spatialRoute from '@acorns-ui/Workspaces/MapWorkspace/MapRoutes';
//import databaseRoute from './database';



import NotFound from './NotFound';
import LoginRoute from '@acorns-ui/Modules/Login/LoginRoute';

Vue.use(VueRouter);


const router = new VueRouter({
  mode: 'history',
  routes: [
    LoginRoute,
    projectRoutes,
    //contactRoutes,
    issueRoutes,
    //regionRoute,
    RegionRoutes,
    // areaRoute,
    AdminRoutes,
    roleAdminRoutes,
    roleRoutes,
    //templateRoute,
    templateAdminRoutes,
    templateRoutes,
    spatialRoute,
    LibraryRoutes,
    propertyTypeRoutes,
    issueTypeRoutes,
  //  databaseRoute,
    {
      path: '/',
      components: {
        page: require('../views/feed/userFeedPage').default
      },
      props: { page: true },
      meta: {
        authAreaName: 'home',
        source: 'page',
        title: 'Home',
      },
    },
    // ModuleRoute({
    //   module: 'views',
    //   path: 'schema',
    //   pkey: 'view_id',
    //   title: 'Registered views',
    //   preview: require('@acorns-ui/Previews/ViewPreview').default,
    // }),
    ModuleRoute({
      module: 'payments',
      pkey: 'tax_payment_id',
      title: 'Tax Payments'
    }),
    ModuleRoute({
      module: 'files',
      // file preview directly binds the props
      preview: require('@acorns-ui/Previews/FilePreview').default,
    }),
    ModuleRoute({
      module: 'observations',
      title: 'Species observations'
    }),
    ModuleRoute({
      module: 'taxa',
      title: 'Species list'
    }),
    ModuleRoute({
      module: 'notes',
      pkey: 'note_id',
      title: 'Notes'
    }),
    ModuleRoute({
      module: 'cameraPoints',
      title: 'Photo Point Locations',
      preview: require('@acorns-ui/Previews/CameraPointPreview').default,
    }),
    ModuleRoute({
      module: 'monitoringElements',
      title: 'Monitoring Elements',
      path: 'elements',
      preview: require('@acorns-ui/Previews/MonitoringElementPreview').default,
    }),
    ModuleRoute({
      module: 'sources',
      title: 'Sources',
      path: 'sources',
      preview: require('@acorns-ui/Workspaces/RestrictionsWorkspace/SourcePreview').default,
    }),
    ModuleRoute({
      module: 'restrictions',
      title: 'Restrictions',
      preview: require('@acorns-ui/Workspaces/RestrictionsWorkspace/RestrictionPreview').default,
    }),
    ModuleRoute({
      module: 'patches',
      title: 'Patches',
      preview: require('@acorns-ui/Previews/PatchPreview').default,
    }),
    ModuleRoute({
      module: 'parcels',
      pkey: 'parcel_id',
      title: 'Parcels',
      // the preview only works with previews that are built to directly
      // bind the props (not the data method)
    //  preview: require('../components/Previews/parcelPreview').default,
    }),
    ModuleRoute({
      module: 'hours',
      pkey: 'hour_id',
      title: 'Work Hours'
    }),
    // ModuleRoute({
    //   module: 'equipment',
    //   pkey: 'equipment_id',
    //   title: 'Equipment'
    // }),
    ModuleRoute({
      module: 'tasks',
      pkey: 'task_id',
      title: 'Tasks',
      //preview: require('../components/Previews/taskPreview').default,
    }),
    ModuleRoute({
      module: 'accounts',
      pkey: 'account_id',
      title: 'Accounts',
     // preview: require('../components/Previews/AccountPreview').default,
    }),
    ModuleRoute({
      module: 'transactions',
      pkey: 'transaction_id',
      title: 'Transactions'
    }),
    ModuleRoute({
      module: 'documents',
      pkey: 'document_id',
      title: 'Documents'
    }),
    ModuleRoute({
      module: 'communications',
      pkey: 'communication_id',
      title: 'Communications'
    }),
    ModuleRoute({
      module: 'contracts',
      pkey: 'contract_id',
      title: 'Contracts'
    }),
    ModuleRoute({
      module: 'invoices',
      pkey: 'invoice_id',
      title: 'Invoices'
    }),
    ModuleRoute({
      module: 'events',
      pkey: 'event_id',
      title: 'Events'
    }),
    ModuleRoute({
      path: 'control',
      module: 'control',
      pkey: 'control_event_id',
      title: 'Control Events',
      // control event preview directly binds
      preview: require('../components/VegControlTool/ControlEventPreview').default,
      admin: require('../components/VegControlTool/ControlEventsAdmin').default,
    }),
  ],
});

// if(process.env.NODE_ENV == 'development') {
//   router.addRoutes([
//     ToolsRoutes,
//   ]);
// }

// router.addRoutes([
//   {
//     path: '*',
//     //components: { modal: NotFound },
//   },
// ]);

router.onError( err => {
  console.log('<!-- router error: ' + err.message + ' -->');
});

router.beforeEach(globalRouteGuard);

export default router;
