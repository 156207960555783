<template>
<FormLayout
  class="source-form"
  :loading="loading"
  :inline="inline"
  :saving="saving"
  :error.sync="error"
  :tabs="tabs"
  :tab.sync="tab"
  @close="closeHandler">


  <Tag
    v-if="!id"
    slot="actions"
    :theme="tab != 'import' ? 'lighten' : 'darken'"
    @clicked="tab = (tab == 'import') ? 'general' : 'import'">
    Import
  </Tag>

  <Tag
    v-if="!id && parent!='property'"
    slot="actions"
    :theme="tab != 'import' ? 'lighten' : 'darken'"
    @clicked="tab = (tab == 'link') ? 'general' : 'link'">
    Link
  </Tag>

  <template
    slot="general">

    <LabeledInput
      label="Label"
      :status="status.label">
      <TextInput
        v-model="record.label"
        :required="true"
        @status-changed="updateStatus('label', $event)"/>
    </LabeledInput>

    <LabeledInput
      v-if="!id && parent == 'property' && parentId"
      label="Parcels"
      :status="status.parcel_id">
      <ChecklistInput
        v-model="record.parcel_id"
        :method="`properties/${parentId}/parcels?f=lookup`"
        :required="true"
        :inline="true"
        :fields="['parcel_id']"
        pkey="parcel_id"
        :multiple="true"
        @status-changed="updateStatus('parcel_id', $event)"/>
    </LabeledInput>

    <div class="input-group">

      <LabeledInput
        label="Grantor"
        :fill="true"
        :status="status.grantor_contact_id">
        <DropdownInput
          v-model="record.grantor_contact_id"
          method="contacts/lookup"
          :allow-search="true"
          new-placeholder="Create a new contact"
          new-form="ContactForm"
          :new-form-params="{ parent, parentId }"
          :required="true"
          @status-changed="updateStatus('grantor_contact_id', $event)"/>
      </LabeledInput>

      <LabeledInput
        label="Grantee"
        :fill="true"
        :status="status.grantee_contact_id">
        <DropdownInput
          v-model="record.grantee_contact_id"
          method="contacts/lookup"
          :allow-search="true"
          new-placeholder="Create a new contact"
          new-form="ContactForm"
          :new-form-params="{ parent, parentId }"
          :required="true"
          @status-changed="updateStatus('grantee_contact_id', $event)"/>
      </LabeledInput>

    </div>

    <div class="input-group">

      <LabeledInput
        label="Source type"
        :fill="true"
        :status="status.source_type_id">
        <DropdownInput
          v-model="record.source_type_id"
          method="restrictionsourcetypes/lookup"
          :required="true"
          @status-changed="updateStatus('source_type_id', $event)"/>
      </LabeledInput>

      <LabeledInput
        label="Source document"
        :fill="true"
        :status="status.document_id">
        <DropdownInput
          v-model="record.document_id"
          :method="`${parent}/${parentId}/documents?f=lookup`"
          pkey="document_id"
          new-placeholder="Create a new document"
          new-form="DocumentForm"
          :fields="['document_id']"
          :new-form-params="{ parent, parentId }"
          :required="false"
          @status-changed="updateStatus('document_id', $event)"/>
      </LabeledInput>

    </div>

    <LabeledInput
      label="Description"
      :fill="true"
      :status="status.description">
      <TextareaInput
        v-model="record.description"
        :required="true"
        @status-changed="updateStatus('description', $event)"/>
    </LabeledInput>



  </template>


  <template
    slot="link">

    <LabeledInput
      label="Source"
      :status="status.parcel_id"
      details="Search for an existing source">
      <DropdownInput
        v-model="record.source_id"
        method="sources/lookup"
        :allow-search="true"
        :required="true"
        :fields="['description']"
        @status-changed="updateStatus('source_id', $event)"
        />
    </LabeledInput>

    <LabeledInput
      v-if="!id && parent == 'property' && parentId"
      label="Parcels"
      :status="status.parcel_id">
      <ChecklistInput
        v-model="record.parcel_id"
        :method="`properties/${parentId}/parcels?f=lookup`"
        :required="true"
        :inline="true"
        :fields="['parcel_id']"
        pkey="parcel_id"
        :multiple="true"
        @status-changed="updateStatus('parcel_id', $event)"/>
    </LabeledInput>

  </template>

  <template
    slot="import">

    <LabeledInput
      label="Property"
      details="Select the property you wish to import from">
      <DropdownInput
        method="properties/lookup"
        :allow-search="true"
        @updated="importPropertySources"/>
    </LabeledInput>

    <LabeledInput
      label="Source types"
      details="Select the type you are looking for">
      <DropdownInput
        method="restrictionsourcetypes/lookup"
        :allow-search="false"
        @updated="importTypeSources"/>
    </LabeledInput>



    <div
      v-if="examples.length"
      class="examples-container">
      <div class="smaller italics">
        Select the source to import from the list. Once you select it you will have the chance to make changes to the source. If you choose to import restrictions as well you will need to alter those after they have been imported.
      </div>

      <LabeledInput
        label="Import restrictions"
        details="Also import all the sources restrictions">
        <CheckboxInput
          v-model="includeRestrictions"/>
      </LabeledInput>

      <ItemLayout
        v-for="(e, i) in examples"
        :key="e.source_id"
        :as-row="false"
        @clicked="importExample(e,i)">
        <div>
          {{ e.label }}: {{ e.source_type.label }}
        </div>
        <div class="smaller italics">
          {{ e.description }}
        </div>
        <div class="smaller italics">
          Number of restrictions: {{ e.restrictions_count }}
        </div>
        <div
          v-if="e.error"
          class="smaller italics alert">
          {{ e.error }}
        </div>
        <div
          v-if="e.saved"
          class="smaller italics success">
          Source imported
        </div>
      </ItemLayout>
    </div>
  </template>

  <CheckboxInput
    slot="footer"
    v-if="exampleSourceId && tab == 'general'"
    label="Include source restrictions"
    v-model="includeRestrictions"/>

  <Button
    v-if="tab == 'general'"
    slot="buttons"
    @click="saveAndClose">
    save
  </Button>

  <Button
    v-if="tab == 'link'"
    slot="buttons"
    :disabled="!record.source_id || !record.parcel_id"
    @click="selectAndClose">
    select
  </Button>

</FormLayout>
</template>

<script>
  import { fetch } from '@/api';

import CollectionFormMixin from '@acorns-ui/Forms/Mixins/CollectionFormMixin';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput'
import DropdownInput from '@acorns-ui/Inputs/DropdownInput'
import TextareaInput from '@acorns-ui/Inputs/TextareaInput'
import TextInput from '@acorns-ui/Inputs/TextInput'
import ChecklistInput from '@acorns-ui/Inputs/ChecklistInput';
import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
import Button from '@acorns-ui/Inputs/Button';
import Tag from '@acorns-ui/Text/Tag';
import ItemLayout from '@acorns-ui/Layouts/ItemLayout/ItemLayout2';

export default {
  name: 'SourceForm',
  mixins: [
    CollectionFormMixin,
    FormValidationMixins,
  ],
  props: {
    collection: {
      default: 'sources'
    },
  },
  data() {
    return {
      tab: 'general',
      examples: [],
      linkedSourceId: null,
      exampleSourceId: null,
      includeRestrictions: false,
      record: {
        label: null,
        description: null,
        grantor_contact_id: null,
        grantee_contact_id: null,
        document_id: null,
        source_type_id: null,
        parcel_id: null,
      }
    };
  },
  components: {
    LabeledInput,
    DropdownInput,
    TextareaInput,
    TextInput,
    FormLayout,
    Button,
    ChecklistInput,
    Tag,
    ItemLayout,
    CheckboxInput,
  },
  created() {},
  computed: {
    tabs() {
      var tabs = [
        { uid: 'general', label: 'General' },
      ];
      if(!this.id) {
        tabs.push({ uid: 'import', label: 'Import', hide: true});
      }
      if(this.parent!='property') {
        tabs.push({ uid: 'link', hide: true});
      }
      return tabs;
    },
    selectedExamples() {
      return this.examples.filter(d=>d.selected);
    },
    hasParent() {
      return this.parent && this.parent == 'property';
    },
  },
  methods: {
    selectAndClose() {
      const id = this.record.source_id;
      if(this.onSave) {
        this.onSave({ id });
        this.$emit('save', { id });
        this.$emit('close');
      } else {
        this.saveData();
      }
    },
    saveAndClose(){
      this.saveData()
        .then( res  => {
          if(this.includeRestrictions && this.exampleSourceId) {
            // download and
            this.importSourceRestrictions(res.id)
              .then( res => {
                this.$emit('close');
              })
          } else {
            this.$emit('close');
          }
        });
    },
    importSourceRestrictions(source_id) {
      const id = this.exampleSourceId;
      fetch(`sources/${id}/restrictions?f=list`)
        .then( list => {
          list.map(d => {
            let data = { source_id };
            data.description = d.description;
            data.classification_id = d.classification.classification_id;
            data.use_id = d.use.use_id;
            this.$store.dispatch('restrictions/addRecord', { data });
          })
          console.log(list);
        })
    },
    updateSelected(idx) {
      const ex = this.examples[idx];
      ex.selected = !ex.selected;
      this.examples.splice(idx,1,ex);
    },
    importTypeSources(id) {
      fetch(`sources/list?source_type_id=${id}`)
        .then( list => {
          this.examples = list;
        })
    },
    importPropertySources(id) {
      fetch(`properties/${id}/sources?f=list`)
        .then( list => {
          this.examples = list;
        })
    },
    importExample(ex, idx) {
      this.updateSelected(idx);
      this.exampleSourceId = ex.source_id;
      this.record.description = ex.description;
      this.record.label = ex.label;
      this.record.source_type_id = ex.source_type.source_type_id;
      this.record.grantor_contact_id = ex.grantor_contact.contact_id;
      this.record.grantee_contact_id = ex.grantee_contact.contact_id;
      this.tab = 'general';
    },
    updateExample(ex) {
      let idx = this.examples.findIndex(d => d.restriction_id == ex.restriction_id);
      if(idx>=0) {
        this.examples.splice(idx, 1, ex);
      }
    },
    importSelectedExamples() {
       let data = {};
      data.document_id = this.record.document_id;
      this.selectedExamples.map( ex => {
        data.description = ex.description;
        data.label = ex.label;
        data.source_type_id = ex.source_type.source_type_id;
        data.grantor_contact_id = ex.grantor_contact.contact_id;
        data.grantee_contact_id = ex.grantee_contact.contact_id;
        this.$store.dispatch('sources/addRecord', { data })
          .then( res => {
            ex.error = null;
            ex.saved = true;
            this.updateExample(ex)
            if(this.onSave) this.onSave(res);
          })
          .catch( err => {
            // update example with error
            ex.error = err.message;
            this.updateExample(ex)
          })
      })
    },
  }
};
</script>

<style lang="scss">
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .source-form {
    @include formsize;
  }
}

.source-form  {

  .description-input {
    flex: 1;
  }
  .element-examples-input {
    flex: 1;
  }

  .element-examples {
    overflow: auto;

    .element-example {
      cursor: pointer;
      display: block;
      &:hover {
        background-color: $hover-bkgd;
      }
      padding: 0.5rem;
      .property-label {
        font-weight: 600;
      }
    }
  }
}
</style>
