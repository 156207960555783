<template>
<FormLayout
  class="tax-payment-form"
  label="Tax Payment"
  :loading="loading"
  :saving="saving"
  :error="error"
  @ok="error = null"
  @close="$emit('close')">


  <Tag
    v-if="!id && !parcelId"
    slot="actions"
    theme="lighten"
    class="switch-tag"
    @clicked="searchByProperty=!searchByProperty">
    {{ searchByProperty ? 'By Parcel' : 'By Property' }}
  </Tag>

  <LabeledInput
    v-if="searchByCounty"
    label="County">
    <DropdownInput
      v-model="county_id",
      method="counties/lookup"
      :allow-search="true"
      />
  </LabeledInput>

  <LabeledInput
    v-if="showPropertyInput"
    :label="propertyLabel">
    <DropdownInput
      v-model="property_id",
      method="properties/lookup"
      :allow-search="!county_id"
      :required="true"
      :args="{ countyId: county_id }"
      />
  </LabeledInput>

  <LabeledInput
    v-if="showParcelInput"
    label="parcel"
    :status="status.parcel_id">
    <DropdownInput
      v-model="record.parcel_id",
      method="parcels/lookup"
      :allow-search="!selectedPropertyId && !county_id"
      :args="{ propertyId: selectedPropertyId, countyId: county_id }"
      :required="true"
      @status-changed="updateStatus('parcel_id', $event)"
      />
  </LabeledInput>

  <LabeledInput
    label="year"
    :status="status.tax_year">
    <NumberInput
      v-model="record.tax_year"
      :required="true"
      @status-changed="updateStatus('tax_year', $event)"
      />
  </LabeledInput>

  <LabeledInput
    label="amount"
    :status="status.amount">
    <NumberInput
      v-model="record.amount"
      :required="true"
      @status-changed="updateStatus('amount', $event)"
      />
  </LabeledInput>

  <LabeledInput
    label="payment date"
    :status="status.payment_date">
    <DateInput
      v-model="record.payment_date",
      default="today"
      :required="true"
      @status-changed="updateStatus('payment_date', $event)"
      />
  </LabeledInput>


  <CheckboxInput
    class="keep-open"
    slot="footer"
    label="Keep open"
    v-model="keepOpen"/>

  <!-- <CheckboxInput -->
  <!--   slot="footer" -->
  <!--   label="Search by county" -->
  <!--   v-model="searchByCounty"/> -->


  <Button
    v-if="id && !deleted"
    slot="buttons"
    title="Delete"
    @click="remove"
    type="delete">
    delete
  </Button>

  <Button
    slot="buttons"
    :disabled="!ready"
    title="Save"
    @click="save"
    type="save">
    Save
  </Button>

  <!-- <template -->
  <!--   slot="buttons"> -->
  <!--   <Button -->
  <!--     :disabled="savingAsync && savingAsync !== 'savingAndCreate'" -->
  <!--     @click="handleSaveAndCreate"> -->
  <!--     <span v-if="savingAsync === 'savingAndCreate'">Saving...</span> -->
  <!--     <span v-if="savingAsync !== 'savingAndCreate'"> -->
  <!--       Save & add another -->
  <!--     </span> -->
  <!--   </Button> -->
  <!--   <Button -->
  <!--     :disabled="savingAsync && savingAsync !== 'savingAndClose'" -->
  <!--     @click="handleSaveAndClose" -->
  <!--     > -->
  <!--     <span v-if="savingAsync === 'savingAndClose'">Saving...</span> -->
  <!--     <span v-if="savingAsync !== 'savingAndClose'">Save</span> -->
  <!--   </Button> -->
  <!-- </template> -->

</FormLayout>
</template>

<script>
import FormLayout from '@acorns-ui/Layouts/FormLayout';
import FormValidationMixins from '@acorns-ui/Forms/Mixins/FormValidationMixins';
import Button from '@acorns-ui/Inputs/Button';
import NumberInput from '@acorns-ui/Inputs/NumberInput';
import DateInput from '@acorns-ui/Inputs/DateInput';
import CheckboxInput from '@acorns-ui/Inputs/CheckboxInput';
import DropdownInput from '@acorns-ui/Inputs/DropdownInput';
import LabeledInput from '@acorns-ui/Inputs/LabeledInput';
import Tag from '@acorns-ui/Text/Tag';
import { post } from '@/api';
import { mapState } from 'vuex';

var initialRecord = {};

export default {
  name: 'TaxPaymentForm',
  mixins: [
    FormValidationMixins,
  ],
  props: {
    id: {
      type: [Number, String],
    },
    parcelId: {
      type: [Number, String],
    },
    propertyId: {
      type: [Number, String],
    },
    callback: {
      type: Function,
      default: (d) => console.log(d),
    },
    defaults: {
      type: Object,
      deafult: ()=>({}),
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      error: null,
      deleted: false, // once I reset after deleting I need to hide the id
      keepOpen: false,
      region_id: null,
      property_id: null,
      county_id: null,
      searchByCounty: false,
      searchByProperty: false,
      record: {
        parcel_id: null,
        amount: null,
        tax_year: new Date().getFullYear(),
        paymentdate: null,
      },
    };
  },
  components: {
    FormLayout,
    Button,
    LabeledInput,
    DateInput,
    NumberInput,
    DropdownInput,
    CheckboxInput,
    Tag,
  },
  created(){
    if(this.parcelId) {
      this.record.parcel_id = this.parcelId;
    }
    if(this.propertyId) {
      this.record.property_id = this.propertyId;
    }
    // before I get the record I want to perserve the current state
    initialRecord = Object.assign({}, this.record);
    if(this.id) {
      // get the record data
      const id = this.id;
      this.$store.dispatch('payments/getRecordRaw', { id })
        .then( res => {
          //console.log('record', res);
          this.record = Object.assign(this.record, res.data);
        });
    }
  },
  computed: {
    ...mapState('Global', [
      'propertyLabelSingular'
    ]),
    selectedPropertyId(){
      return this.propertyId || this.property_id;
    },
    showPropertyInput(){
      return !this.parcelId
        && !this.id
        && this.searchByProperty
    },
    showParcelInput(){
      return !this.parcelId
        && (!this.searchByProperty ||this.selectedPropertyId)
        && (!this.searchByCounty || this.county_id);
    },
    propertyLabel(){
      return this.$store.state['propertyLabelSingular'] || 'Property';
    }
  },
  methods: {
    save(){
      this.saving = true;
      const params = this.id && !this.deleted
            ? { id: this.id, data: this.record }
            : { data: this.record };
      const endpoint = this.id && !this.deleted
            ? 'payments/updateRecord'
            : 'payments/addRecord'
      this.$store.dispatch(endpoint,  params)
        .then( res => {
          this.callback(res);
          if(!this.keepOpen) {
            this.$emit('close');
          }
        })
        .catch( err => this.error = err)
        .finally( res => {
          this.saving = false;
          this.reset();
        });
    },
    remove(){
      this.saving = true;
      const data = { id: this.id };
      this.$store.dispatch('payments/removeRecord', { data })
        .then( res => {
          this.callback(res);
          this.saving = false
          this.deleted = true;
          if(!this.keepOpen) {
            this.$emit('close');
          } else {
            this.reset();
          }
        });
    },
    reset(){
      this.record = { ...this.record, amount: null, parcel_id: null }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
@import '~@acorns-ui/Styles/screens';

.modal {
  .tax-payment-form {
    @include formwidth;
  }
}

.tax-payment-form {

  .form-content {
    padding: 1rem;
  }

  .switch-tag {
    cursor: pointer;
    &:hover {
      background-color: rgba(0,0,0,0.25);
      color: $white;
    }
  }


}
</style>
