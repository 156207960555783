import ProjectForm from '@acorns-ui/Forms/ProjectForm';
import ContactForm from '@acorns-ui/Forms/ContactForm';
import DocumentForm from '@acorns-ui/Forms/DocumentForm';
import MessageForm from '@acorns-ui/Forms/MessageForm';
import IssueForm from '@acorns-ui/Forms/IssueForm';
import TaskForm from '@acorns-ui/Forms/TaskForm';
import WorkForm from '@acorns-ui/Forms/WorkForm';
import NoteForm from '@acorns-ui/Forms/NoteForm';
import FileForm from '@acorns-ui/Forms/FileForm';
import HoursForm from '@acorns-ui/Forms/HoursForm';
import AttributeForm from '@acorns-ui/Forms/AttributeForm';
import FileReview from '@acorns-ui/Forms/FileReview';
import CollectionForm from '@acorns-ui/Forms/CollectionForm';
import Form from '@acorns-ui/Forms/Form';
import DeliverableForm from '@acorns-ui/Forms/DeliverableForm';
import ObjectRoleForm from '@acorns-ui/Forms/ObjectRoleForm';
import RoleForm from '@acorns-ui/Forms/RoleForm';
import EmailForm from '@acorns-ui/Forms/EmailForm';
import AddressForm from '@acorns-ui/Forms/AddressForm';
import PhoneForm from '@acorns-ui/Forms/PhoneForm';
import ReplyForm from '@acorns-ui/Forms/ReplyForm';
import SelectPropertyForm from '@acorns-ui/Forms/SelectPropertyForm';
import PropertyForm from '@acorns-ui/Forms/PropertyForm';
import VisitForm from '@acorns-ui/Forms/VisitForm';
import CameraPointForm from '@acorns-ui/Forms/CameraPointForm';
import StatusForm from '@acorns-ui/Forms/StatusForm';
import LeadForm from '@acorns-ui/Forms/LeadForm';
import RelationshipForm from '@acorns-ui/Forms/RelationshipForm';
import PlannedTaskForm from '@acorns-ui/Forms/PlannedTaskForm';
import GroupForm from '@acorns-ui/Forms/GroupForm';
import EventForm from '@acorns-ui/Forms/EventForm';
import LayerForm from '@acorns-ui/Forms/LayerForm';
import FeatureForm from '@acorns-ui/Forms/FeatureForm';
import ParcelForm from '@acorns-ui/Modules/ParcelsModule/ParcelForm';
import PhotoPointImageDetails from '@acorns-ui/Workspaces/PhotoPointsWorkspace/PhotoPointImageDetails';
import ObjectFieldForm from '@acorns-ui/Modules/CollectionModule/ObjectFieldForm';
import CollectionSelectForm from '@acorns-ui/Forms/CollectionSelectForm';
import FilterForm from '@acorns-ui/Forms/FilterForm';
import FieldForm from '@acorns-ui/Forms/FieldForm';

import PreviewFactory from '@/components/Previews/PreviewFactory';
import ObservationForm from '@/components/Forms/ObservationForm';
import TemplateFieldForm from '@/components/Forms/TemplateFieldForm';


//import FeatureForm from '@/components/Forms/FeatureForm';

//import LayerForm from '@/components/Forms/LayerForm';
import ControlEventForm from '@/components/VegControlTool/ControlEventFormV2';
//import ControlEventForm from '@/components/VegControlTool/ControlEventFormV2';
import MonitoringElementForm from '@/components/Forms/MonitoringElementForm';
import RestrictionForm from '@/components/Forms/RestrictionForm';
import SourceForm from '@/components/Forms/SourceForm';
import ApprovalForm from '@/components/Forms/ApprovalForm';
import MonitoringElementObservationForm from '@/components/Forms/MonitoringElementObservationForm';
import TaxPaymentForm from '@/components/Forms/TaxPaymentForm';
import SavedSearchForm from '@/components/Forms/SavedSearchForm';

//import ModalStandin from './ModalStandin';

export default {
  props: {
    defaultComponent: {
      type: String,
     // default: 'ModalStandin',
    }
  },
  components: {
    RestrictionForm,
    MonitoringElementForm,
    SourceForm,
    ApprovalForm,
    ParcelForm,
    AddressForm,
    EmailForm,
    PhoneForm,
    ReplyForm,
    ObjectRoleForm,
    RoleForm,
    ObjectFieldForm,
    TemplateFieldForm,
    FilterForm,
    FieldForm,
    FeatureForm,
    TaxPaymentForm,
    ControlEventForm,
    PropertyForm,
    ProjectForm,
    HoursForm,
    DocumentForm,
    LayerForm,
    AttributeForm,
    FileForm,
    FileReview,
    PreviewFactory,
    Form,
    CollectionForm,
    NoteForm,
    ObservationForm,
    MessageForm,
    ContactForm,
    DeliverableForm,
    PhotoPointImageDetails,
    SavedSearchForm,
    SelectPropertyForm,
    StatusForm,
    LeadForm,
    IssueForm,
    TaskForm,
    WorkForm,
    VisitForm,
    MonitoringElementObservationForm,
    CameraPointForm,
    RelationshipForm,
    GroupForm,
    PlannedTaskForm,
    EventForm,
    CollectionSelectForm,
  }
};
