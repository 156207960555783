import Vue from 'vue';
import moment from 'moment';
import * as d3 from 'd3';
// getTimezoneOffset is in minutes
const OFFSET = new Date().getTimezoneOffset()/60 * -1;

Vue.filter('percent', (value) => {
  if (!value) return '0%';
  return `${Math.round(value * 100)}%`;
});

Vue.filter('round', (value, pos = 0) => {
  if (!value) return '0';
  return d3.format(`,.${pos}f`)(value);
});

Vue.filter('acres', (value) => {
  if (!value) return '--';
  if (value >= 1000) {
    return d3.format(',.0f')(value);
  } else if (value > 1) {
    return d3.format('.1f')(value);
  } else {
    return d3.format('.2f')(value);
  }
});

Vue.filter('title', (value) => {
  return value.replace(/_/g, ' ');
});

Vue.filter('money', (value) => {
  if (!value || value == '$0.00') {
    return '--';
  } else if(!value.replace) {
    return d3.format('$,.2f')(value);
  } else if(value.startsWith('$')) {
    return value;
  } else {
    return d3.format('$,.2f')(value.replace(/\$|,/gi, ''));
  }
});

Vue.filter('count', (value) => {
  if (!value) return '0';
  return d3.format(',')(value);
});

Vue.filter('date', (value, format = 'MMMM DD, YYYY') => {
  if (!value) return '';
  if (value === 'infinity') return '--';
  const dt = moment(value, 'YYYY-MM-DD');
  return dt.format(format);
});

Vue.filter('shortDate', (value) => {
  if (!value) return '';
  if (value === 'infinity') return '--';
  const dt = moment(value, 'YYYY-MM-DD');
  return dt.format('MM/DD/YYYY');
});


Vue.filter('time', (value, offset = OFFSET) => {
  if (!value) return '';
  if (value === 'infinity') return '--';
  var dt = moment(value,'YYYY-MM-DD HH:mm:ss');
  if(offset) {
    dt.add(offset, 'hours');
  }
  return dt.format('h:mm a');
});

Vue.filter('datetime', (value, offset = OFFSET) => {
  if (!value) return '';
  if (value === 'infinity') return '--';
  var dt = moment(value,'YYYY-MM-DD HH:mm:ss');
  if(offset) {
    dt.add(offset, 'hours');
  }
  return dt.format('MMMM DD YYYY, h:mm a');
});

Vue.filter('year', (value) => {
  if (!value) return '';
  if (value === 'infinity') return '';
  const dt = moment(value, 'YYYY-MM-DD');
  return dt.format('YYYY');
});

Vue.filter('fromNow', (value, offset = OFFSET) => {
  if (!value) return '';
  if (value === 'infinity') return '';
  var dt = moment(value,'YYYY-MM-DD HH:mm:ss');
  if(offset) {
    dt.add(offset, 'hours');
  }
  return dt.fromNow();
});

Vue.filter('debug', (value) => {
  if (!value) return '';
  return JSON.stringify(value);
});

Vue.filter('phone', (value) => {
  if (!value) return '';
  const v = (`${value}`).replace(/\D/g, '');
  const m = v.match(/^(\d{3})(\d{3})(\d{4})$/);
  return (!m) ? value : `(${m[1]}) ${m[2]}-${m[3]}`;
});
